import React, { useState } from 'react'
import { Modal } from 'antd';
import { FileBrowser, openNotificationWithIcon } from './FileBrowser';
import { getDownloadURL, getMetadata } from './Functions';

const FileBrowserModal = (props) => {

  const [selectedFile, setSelectedFile] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false);
  const fileType = props.type

  const onSelect = (file) => {
    setSelectedFile(file)
  }

  const onOk = () => {
    setConfirmLoading(true)
    const validType = !fileType || (fileType && (fileType === 'any' || selectedFile.metadata.contentType.startsWith(fileType)))
    if (validType) {
      getDownloadURL(selectedFile.id).then((url) => {
        setConfirmLoading(false)
        if (props.onOk) {
          props.onOk({ url: url, metadata: selectedFile.metadata })
        }
      })
    } else {
      openNotificationWithIcon('error', 'Please select a file with valid type')
      setConfirmLoading(false)
    }

  }

  return (
    <Modal
      title="File Storage"
      open={props.visible}
      onOk={onOk}
      okButtonProps={{ disabled: !selectedFile }}
      onCancel={props.onCancel}
      confirmLoading={confirmLoading}
      width='70%'
      zIndex={5000}
      centered={props.centered}
    >
      <FileBrowser activeCompany={props.activeCompany} onSelect={onSelect} disableDragAndDropProvider={props.disableDragAndDropProvider} />
    </Modal>
  )
}

export default FileBrowserModal