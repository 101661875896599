import React, { Component } from 'react'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import Loading from '../../components/Loading/Loading'
import { Row, Col, FormControl } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'
import { firebaseDb as db, storageRef as storage } from '../../firebase'
import Button from '../../components/CustomButton/CustomButton'
import { connect } from 'react-redux'
import _ from 'lodash'

// Layouts
import layout1 from './gridLayouts/layout1.json'
import layout2 from './gridLayouts/layout2.json'
import layout3 from './gridLayouts/layout3.json'
import { generateMemoryGamePair } from '../../utils/generateMemoryGamePair'

const ReactGridLayout = WidthProvider(GridLayout)
let COLUMNS = 6
const MARGIN = 5
const storageRef = storage.ref()

class Grid extends Component {
  state = {
    color1: null,
    color2: null,
    color3: null,
    backGroundImage: null,
    loadingGrid: true,
    initLayount: false,
    loadingBackground: true,
    width: window.innerWidth,
    layoutPredefined: 'default'
  }

  componentDidMount() {
    this.setState(
      {
        ...this.state,
        activeCompany: this.props.activeCompany,
        activeCalendar: this.props.activeCalendar,
        calendarType: this.props.calendarType
      },
      this.loadFromDb
    )

    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ...this.state,
        activeCompany: nextProps.activeCompany,
        activeCalendar: nextProps.activeCalendar,
        calendarType: nextProps.calendarType
      },
      this.loadFromDb
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions() {
    this.setState({ ...this.state, width: window.innerWidth })
  }

  loadFromDb = async () => {
    this.state.activeCompany &&
      this.state.activeCalendar &&
      db
        .doc(
          'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          if (data) {
            this.setState({
              ...this.state,
              colors: data.colors,
              lastDays: data.days,
              numGridCols: data.numGridCols,
              days: data.days,
              loading: false
            })
          }

          if(this.state.calendarType === 'memory-game') {
            const missingdayPairs = Object.keys(data.days).filter(dayKey => !dayKey.includes('pair_') && !dayKey.includes('winning') && !data.days[`pair_${dayKey}`]).sort((a, b) => a - b);
          
            if(missingdayPairs.length > 0) {
              this.updateDayPair(missingdayPairs);
            }
          }

          let backgroundImage = data.backgroundStorage || data.backgroundImage
          if (backgroundImage === 'removed') {
            backgroundImage = ''
          }
          this.setState({
            ...this.state,
            backGroundImage: backgroundImage,
            loadingBackground: false
          })

          let customIconUrl = {}
          const dayEntries = this.state.days && Object.entries(this.state.days)
          const len = dayEntries && dayEntries.length
          for (let i = 0; i < len; i++) {
            const index = dayEntries[i][0];
            const days = dayEntries[i][1];
            if (days.customIcon && days.customIcon !== 'removed') {
              if (days.customIcon.includes('https://')) {
                customIconUrl[index] = days.customIcon
                continue
              }
              await storageRef
                .child(
                  'company/' +
                  this.state.activeCompany +
                  '/calendar/' +
                  this.state.activeCalendar +
                  '/dayCustomIcons/' +
                  days.customIcon
                )
                .getDownloadURL()
                .then(url => {
                  customIconUrl[index] = url
                })
                .catch(error => {
                  this.notificationOpen('error', error)
                })
            } else {
              await storageRef
                .child('default/calendar/advent/icons/white/' + index + 'w.svg')
                .getDownloadURL()
                .then(url => {
                  customIconUrl[index] = url
                  if(this.state.calendarType === 'memory-game') {
                    customIconUrl[`pair_${index}`] = url
                  }
                })
                .catch(error => {
                  error.code !== 'storage/object-not-found' &&
                    console.log(error)
                  // this.notificationOpen('error', error)
                })
            }
          }
          this.setState({
            ...this.state,
            customIconUrl: customIconUrl
          })
        })
  }

  notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      this.props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'Error: ' + errorMessage
      this.props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  updateDayPair = (missingDayPairList) => {
    const newDayObj = generateMemoryGamePair(missingDayPairList, this.state.days);
    
    db.doc(
        'companies/' +
        this.state.activeCompany +
        '/calendars/' +
        this.state.activeCalendar +
        '/settings/grid'
      )
        .update({ days: newDayObj })
        .then(() => {
          this.setState({
            ...this.state,
            days: newDayObj
          })
        })
        .catch(error => {
          this.notificationOpen('error', error)
        })
  }

  onLayoutChange = (layout, reload) => {
    let newDays = { ...this.state.days }
    _.forEach(layout, (day, i, a) => {
      newDays[day.i].coords = {
        0: day.x,
        1: day.y,
        2: day.w,
        3: day.h
      }
    })

    let udpateContent = {
      days: newDays
    }
    if (!this.state.initLayount) {
      this.setState({ ...this.state, initLayount: true })
    } else {
      db.doc(
        'companies/' +
        this.state.activeCompany +
        '/calendars/' +
        this.state.activeCalendar +
        '/settings/grid'
      )
        .update(udpateContent)
        .then(() => {
          db.doc(
            'companies/' +
            this.state.activeCompany +
            '/calendars/' +
            this.state.activeCalendar
          )
            .update({ changeToPublish: true })
            .then(res => {
              reload && this.reload()
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          this.notificationOpen('error', error)
        })
    }
  }

  layoutPredefinedChange = event => {
    let layoutContent
    let newDays = this.state.days
    const layout = event.target.value

    switch (layout) {
      case 'layout1':
        layoutContent = layout1
        break
      case 'layout2':
        layoutContent = layout2
        break
      case 'layout3':
        layoutContent = layout3
        break
      default:
        layoutContent = layout1
    }


    if (layout === 'layout3') {
      const size = _.filter(newDays, (day, key) => !isNaN(Number(key))).length;
      let count = 1
      for (let key in layoutContent) {
        if(count > size) break;
        if(!newDays[key]) {
          continue;
        }
        newDays[key].coords = layoutContent[key].coords
        if(newDays[`pair_${key}`] && !isNaN(Number(key))) {
          newDays[`pair_${key}`].coords = layoutContent[(size+Number(key)).toString()].coords
        }
        count++;
      }
      this.setState(
        { ...this.state, layoutPredefined: event.target.value, days: newDays },
        this.onLayoutChange('', true)
      )
    }
    else if (layout !== 'custom') {
      _.forEach(layoutContent, (day, index) => {
        if(newDays[index]) newDays[index].coords = day.coords
      })
      this.setState(
        { ...this.state, layoutPredefined: event.target.value, days: newDays },
        this.onLayoutChange('', true)
      )
    }
  }

  reload = () => {
    const current = this.props.location.pathname
    this.props.history.replace(`/reload`)
    setTimeout(() => {
      this.props.history.replace(current)
    })
  }

  render() {
    const { t } = this.props

    const colorOfBox = {
      color: '#FFF'
    }
    let bgGrid = {
      backgroundImage: `url('${this.state.backGroundImage}')`,
      backgroundColor: 'rgb(179 179 179)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center -300px',
      backgroundAttachment: 'fixed',
      overflow: 'scroll',
      height: '100px'
    }
    if (_.size(this.state.days) === 0) {
      bgGrid = null
    }

    if (this.state.numGridCols) {
      COLUMNS = this.state.numGridCols
    }
    return (
      <div className='content' style={bgGrid}>
        {!this.state.loadingBackground &&
          this.state.days &&
          _.size(this.state.days) === 0 && (
            <Row style={{ textAlign: 'center' }}>
              <Col xs={0} sm={2} md={4} />
              <Col xs={12} sm={6} md={4}>
                <div className='logo' style={{ textAlign: 'center' }}>
                  <i
                    className='pe-7s-photo-gallery'
                    style={{
                      fontSize: '150px',
                      marginBottom: '20px',
                      color: 'green'
                    }}
                  />
                  <h3>{t('calGrid.pleaseAddDaytoChange')}</h3>
                </div>

                <NavLink
                  to={
                    '/company/' +
                    this.state.activeCompany +
                    '/calendar/' +
                    this.state.activeCalendar +
                    '/caldays'
                  }
                >
                  <Button fill bsStyle='success'>
                    {t('calGrid.manageDays')}
                  </Button>
                </NavLink>
              </Col>
              <Col xs={0} sm={2} md={4} />
            </Row>
          )}
        {this.state.loadingGrid && this.state.loadingBackground ? (
          <Row className='show-grid' float='center'>
            <Col xs={12} xsOffset={6}>
              <Loading type='spin' color='green' />
            </Col>
          </Row>
        ) : (
          <div>
            <Row
              style={{
                textAlign: 'right',
                marginTop: '-20px',
                paddingBottom: '7px'
              }}
            >
              <Col xs={0} sm={6} md={9} />
                <Col xs={12} sm={6} md={3}>
                  {/* TODO: fix whatever is happening here (crash after select change) */}
                  <FormControl
                    componentClass='select'
                    name='layoutPredefined'
                    placeholder='select'
                    value={this.state.layoutPredefined}
                    onChange={this.layoutPredefinedChange}
                    ref='allowPreviousDays'
                  >
                    <option value='custom'>{t('calGrid.editedGrid')}</option>
                    <option value='layout1'>{t('calGrid.gridLayout1')}</option>
                    <option value='layout2'>{t('calGrid.gridLayout2')}</option>
                    <option value='layout3'>{t('calGrid.gridLayout3')}</option>
                  </FormControl>
                </Col>
            </Row>
            <ReactGridLayout
              onLayoutChange={this.onLayoutChange}
              className='layout'
              margin={[MARGIN, MARGIN]}
              cols={COLUMNS}
              rowHeight={this.state.width / COLUMNS - COLUMNS * 2 * MARGIN}
            >
              {this.state.days &&
                Object.keys(this.state.days).map(el => {
                  let bgIcon = null
                  if (this.state.customIconUrl) {
                    bgIcon =
                      this.state.customIconUrl && this.state.customIconUrl[el]
                  }
                  const bgColor = {
                    padding: '5px',
                    backgroundColor: this.state.days[el].color,
                    textAlign: '',
                    display: el && el.toString().includes('winning') ? 'none' : 'block',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '60%',
                    backgroundPosition: 'center center',
                    backgroundImage: bgIcon && `url(${bgIcon})`,
                    border: '1px solid #fff'
                  }
                  return (
                    <div
                      key={el}
                      style={bgColor}
                      data-grid={{
                        x: this.state.days[el].coords[0],
                        y: this.state.days[el].coords[1],
                        w: this.state.days[el].coords[2],
                        h: this.state.days[el].coords[3]
                      }}
                    >
                      <h4 style={colorOfBox}>{el}.</h4>
                    </div>
                  )
                })}
            </ReactGridLayout>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { activeCalendar, activeCompany, companiesData } = state
  const type = activeCalendar &&
    activeCompany &&
    companiesData &&
    companiesData[activeCompany].calendars[activeCalendar].type
  return {
    activeCalendar: activeCalendar,
    activeCompany: activeCompany,
    companiesData: companiesData,
    calendarType: type
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(Grid))
