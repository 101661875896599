import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'
import ResizableQuill from '../../../../components/FormInputs/ResizableQuill'
import ReactQuill from 'react-quill'
import { Card } from './../../../../components/Card/Card'
import UploadSingleImage from '../../../../components/FormInputs/UploadSingleImage'
import FileSelector from '../../../FileStorage/Parts/FileSelector'
// import ReactTooltip from 'react-tooltip'
import { translate } from 'react-i18next'
import _ from 'lodash'
import { Switch } from 'antd'
import { getRichContentMaxWidth } from '../../../../utils'

const richTextLimit = 800000; //800kb

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [('bold', 'italic', 'underline', 'strike', 'blockquote')],
    [{ align: [] }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image'],
    ['clean']
  ]
}

// Redux
import { useSelector } from 'react-redux'
const Settings = props => {
  const { activeCalendar, activeCompany } = useSelector(state => state)
  const [counter, setCounter] = useState(0)
  let formData = {}
  let newFormData = {}
  const {
    t,
    contentEditor,
    daysGridDataColors,
    handleChangeSaved,
    handleChangeValues,
    daysGridData,
    dayData = {},
    calendarType,
    selectedForm,
    setError
  } = props

  // input fields
  const [dayIndex, setDayIndex] = useState('')
  const [hubspotFormId, setHubspotFormId] = useState('')
  const [afterFormMessage, setAfterFormMessage] = useState('')
  const [numberOfAttemptsText, setNumberOfAttemptsText] = useState('')
  const [start, setStart] = useState('')
  const [stop, setStop] = useState('')
  const [stopText, setStopText] = useState('')
  const [color, setColor] = useState()
  const [formSubmitClicks, setFormSubmitClicks] = useState('')
  const [bgPos, setBgPos] = useState()
  const [externalPageEnable, setExternalPageEnable] = useState()
  const [externalPageLink, setExternalPageLink] = useState()
  const [icon, setIcon] = useState()
  const [contentIcon, setContentIcon] = useState()
  const [richContent, setRichContent] = useState()
  const [contentType, setContentType] = useState("contentIcon")
  const [articleImage, setArticleImage] = useState()
  const [disableOtherBoxes, setDisableOtherBoxes] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false);
  const [failsGreaterThan, setFailsGreaterThan] = useState('')
  const [failsLessThan, setFailsLessThan] = useState('')

  useEffect(() => {
    checkError();
    handleChangeSettings()
    // if
    if (!initialLoad) {
      setInitialLoad(true)
    } else {
      setCounter(counter + 1)
    }
  }, [
    dayIndex,
    hubspotFormId,
    afterFormMessage,
    start,
    stop,
    stopText,
    color,
    formSubmitClicks,
    bgPos,
    externalPageEnable,
    externalPageLink,
    icon,
    contentIcon,
    richContent,
    contentType,
    articleImage,
    disableOtherBoxes,
    failsGreaterThan,
    failsLessThan,
    numberOfAttemptsText
  ])

  useEffect(() => {
    counter > 1 ? handleChangeSaved(false) : handleChangeSaved(true)
  }, [counter])

  const FLT_value = !isNaN(failsLessThan) ? Number(failsLessThan) : 0
  const FGT_value = !isNaN(failsGreaterThan) ? Number(failsGreaterThan) : 0
  const checkError = () => {
    if (richContentSize >= richTextLimit) {
      setError("Rich text document to large")
      return
    }

    if(isNaN(Number(dayIndex))) {
      setError("Day index must be a number")
      return
    }

    if (afterFormMessageSize >= richTextLimit) {
      setError("After form message to large")
      return
    }

    if ((FLT_value && FLT_value <= FGT_value) || (FGT_value && FLT_value && FGT_value >= FLT_value)) {
      setError("Number of fails range error")
      return
    }
    setError()
  }

  useEffect(() => {
    // get form inputs from DB just on page load first time
    setDayIndex(formData.formId || '')
    setHubspotFormId(formData.hubspotFormId || '')
    setAfterFormMessage(formData.afterFormMessage && formData.afterFormMessage !== 'undefined' ? formData.afterFormMessage : '')
    setNumberOfAttemptsText(formData.numberOfAttemptsText || '')
    setStart(formData.start || '')
    setStop(formData.stop || '')
    setStopText(formData.stopText || '')
    setFormSubmitClicks(formData.formSubmitClicks || '')
    setDisableOtherBoxes(formData.disableOtherBoxes || false)
    handleChangeSaved(true)
    setExternalPageEnable(formData.externalPageEnable || false)
    setExternalPageLink(formData.externalPageLink || '')
    setRichContent(formData.richContent || '')
    setContentType(formData.contentType || 'contentIcon')
    setFailsGreaterThan(formData.failsGreaterThan || '')
    setFailsLessThan(formData.failsLessThan || '')
    if (dayData && dayData.formId && daysGridData[dayData.formId] && daysGridData[dayData.formId].customIcon && daysGridData[dayData.formId].customIcon.includes('https://')) {
      setIcon(daysGridData[dayData.formId].customIcon)
    } else {
      setIcon()
    }
    if (dayData && dayData.formId && daysGridData[dayData.formId] && daysGridData[dayData.formId].customIconContent && daysGridData[dayData.formId].customIconContent.includes('https://')) {
      setContentIcon(daysGridData[dayData.formId].customIconContent)
    } else {
      setContentIcon()
    }
    if (dayData.image && dayData.image.includes('https://')) {
      setArticleImage(dayData.image)
    } else {
      setArticleImage()
    }
    if (dayData && dayData.formId && daysGridData && daysGridData[dayData.formId]) {
      setColor(daysGridData[dayData.formId].color)
      setBgPos(daysGridData[dayData.formId].bgPos)
    }
    setError()
    setTimeout(() => { setCounter(1) }, 100)

  }, [dayData, daysGridData])

  if (dayData) {
    formData = dayData
  }
  // todo
  // const calendarType = 'hide-and-seek'

  const ColDisplayStyle = {
    // display: calendarType === 'hide-and-seek' ? 'none' : 'block'
    display: (['hide-and-seek', 'memory-game'].includes(calendarType)) ? 'none' : 'block'
  }

  const fieldDisplayStyle = {
    display: (['memory-game'].includes(calendarType) && selectedForm && !selectedForm.toString().includes('winning')) ? 'none' : 'block'
  }

  const displayByFormStyle = {
    display: selectedForm && selectedForm.toString().includes('winning') ? 'none' : 'block'
  }

  const richContentSize = richContent && new Blob([richContent]).size || 10
  const afterFormMessageSize = afterFormMessage && new Blob([afterFormMessage]).size || 10

  const handleChangeSettings = () => {
    newFormData = {
      hubspotFormId,
      afterFormMessage,
      start,
      stop,
      stopText,
      color,
      formSubmitClicks,
      bgPos,
      externalPageEnable,
      externalPageLink,
      icon,
      contentIcon,
      articleImage,
      disableOtherBoxes,
      richContent,
      contentType,
      failsLessThan,
      failsGreaterThan,
      numberOfAttemptsText
    }
    if(dayIndex !== formData.formId && !isNaN(Number(dayIndex))) {
      newFormData = {
        dayIndex: Number(dayIndex),
        ...(newFormData || {})
      }
    }
    handleChangeValues(newFormData)
  }

  return (
    <>
      <Row
        style={{
          display: contentEditor === 'settings' ? 'block' : 'none'
        }}
      >
        <Col md={12}>
          <Card
            title={t('calDays.formBuilder.editDaySettings')}
            content={
              <form>
                <Row>
                  {selectedForm && !selectedForm.toString().includes('winning') && selectedForm !== 'winning' && <Col md={4}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.hubspot')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>Day number</ControlLabel>
                      <FormControl
                        type='text'
                        name='dayIndex'
                        id='dayIndex'
                        value={dayIndex}
                        onChange={e => {
                          setDayIndex(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>}
                  {(calendarType === 'hide-and-seek' || calendarType === 'memory-game') && selectedForm && selectedForm.toString().includes('winning') && selectedForm !== 'winning' && <Fragment>
                    <Col md={3}>
                      <FormGroup validationState={FLT_value && FLT_value <= FGT_value ? "error" : null}>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={""}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calDays.settingsInput.failsGreaterThan')}
                        </ControlLabel>
                        <FormControl
                          type='number'
                          name='failsGreaterThan'
                          id='failsGreaterThan'
                          value={failsGreaterThan}
                          onChange={e => {
                            setFailsGreaterThan(e.target.value)
                          }}

                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup validationState={FGT_value && FLT_value && FGT_value >= FLT_value ? "error" : null}>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={""}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calDays.settingsInput.failsLessThan')}
                        </ControlLabel>
                        <FormControl
                          type='number'
                          name='failsLessThan'
                          id='failsLessThan'
                          value={failsLessThan}
                          onChange={e => {
                            setFailsLessThan(e.target.value)
                          }}
                        />
                        {FGT_value && FLT_value && FGT_value >= FLT_value ? <HelpBlock>Value must be greater than {FGT_value}</HelpBlock> : ""}
                      </FormGroup>
                    </Col>
                  </Fragment>}
                  {calendarType === 'hide-and-seek' && selectedForm && selectedForm.toString().includes('winning') && <Col md={6}>
                    <FormGroup>
                      <ControlLabel>Show only winning box after a player wins</ControlLabel>
                      <div>
                        <Switch checked={disableOtherBoxes} onChange={(value) => setDisableOtherBoxes(value)} />
                      </div>
                    </FormGroup>
                  </Col>}
                </Row>
                <Row style={displayByFormStyle}>
                  <Col md={2} style={fieldDisplayStyle}>
                    <FormGroup>
                      <ControlLabel>
                        {t('calDays.formBuilder.externalPageEnable.label')}
                      </ControlLabel>
                      <FormControl
                        componentClass='select'
                        name='externalPageEnable'
                        id='externalPageEnable'
                        placeholder='select'
                        value={String(externalPageEnable)}
                        onChange={e => {
                          setExternalPageEnable(
                            e.target.value === 'true' ? true : false
                          )
                        }}
                      >
                        <option value='false'>
                          {t('calDays.formBuilder.externalPageEnable.no')}
                        </option>
                        <option value='true'>
                          {t('calDays.formBuilder.externalPageEnable.yes')}
                        </option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col md={8} style={fieldDisplayStyle}>
                    <FormGroup>
                      <ControlLabel>
                        {t('calDays.formBuilder.externalPageLink')}
                      </ControlLabel>
                      <FormControl
                        type='text'
                        name='externalPageLink'
                        id='externalPageLink'
                        value={externalPageLink}
                        onChange={e => {
                          setExternalPageLink(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={fieldDisplayStyle}>
                  <Col md={4}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.hubspot')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.hubspotId')}
                      </ControlLabel>
                      <FormControl
                        type='text'
                        name='hubspotFormId'
                        id='hubspotFormId'
                        value={hubspotFormId}
                        onChange={e => {
                          setHubspotFormId(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={fieldDisplayStyle}>
                  <Col md={6}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.hubspot')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.formSubmit')}
                      </ControlLabel>

                      <FormControl
                        componentClass='select'
                        name='formSubmitClicks'
                        placeholder=''
                        value={formSubmitClicks}
                        onChange={e => {
                          setFormSubmitClicks(e.target.value)
                        }}
                      >
                        <option value='true'>
                          {t('calDays.formBuilder.formSubmitYes')}
                        </option>
                        <option value='false'>
                          {t('calDays.formBuilder.formSubmitNo')}
                        </option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{
                  display: (calendarType === 'memory-game' && selectedForm && selectedForm.toString().includes('winning')) ? 'block' : 'none'
                }}>
                  <Col md={12}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.numberOfAttemptsText')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.numberOfAttemptsText')}
                      </ControlLabel>
                      <FormControl
                        type='text'
                        name='numberOfAttemptsText'
                        id='numberOfAttemptsText'
                        value={numberOfAttemptsText}
                        onChange={e => {
                          setNumberOfAttemptsText(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={fieldDisplayStyle}>
                  <Col md={12}>
                    <FormGroup validationState={afterFormMessageSize >= richTextLimit ? "error" : null}>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.afterFormMessage')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.messageAfterFormSubmit') + ", Image Max Width " + getRichContentMaxWidth(calendarType)}
                      </ControlLabel>
                      {/* <FormControl
                        type='text'
                        name='afterFormMessage'
                        id='afterFormMessage'
                        value={afterFormMessage}
                        onChange={e => {
                          setAfterFormMessage(e.target.value)
                        }}
                      /> */}
                      {/* <ReactQuill
                        value={afterFormMessage || ''}
                        id='afterFormMessage'
                        modules={modules}
                        onChange={(e) => { setAfterFormMessage(e) }}
                      /> */}
                      <ResizableQuill
                        value={afterFormMessage || ''}
                        id='afterFormMessage'
                        name='afterFormMessage'
                        onChange={(value) => setAfterFormMessage(value)}
                      />
                      {afterFormMessageSize >= richTextLimit && <HelpBlock>Document too large. Limit: 800kb</HelpBlock>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} style={ColDisplayStyle}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.startDate')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.startDate')}
                      </ControlLabel>
                      <FormControl
                        type='date'
                        name='start'
                        id='start'
                        value={start}
                        min={new Date(2018, 0, 2, 0, 0, 0, 0).toISOString().split("T")[0]}
                        onChange={e => {
                          setStart(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} style={ColDisplayStyle}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.stopDate')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.stopDate')}
                      </ControlLabel>
                      <FormControl
                        type='date'
                        name='stop'
                        id='stop'
                        value={stop}
                        min={new Date(2018, 0, 2, 0, 0, 0, 0).toISOString().split("T")[0]}
                        onChange={e => {
                          setStop(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8} style={ColDisplayStyle}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.stopDate')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.stopText')}
                      </ControlLabel>
                      <FormControl
                        type='text'
                        name='stopText'
                        id='stopText'
                        value={stopText}
                        onChange={e => {
                          setStopText(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={displayByFormStyle}>
                  <Col md={3} style={fieldDisplayStyle}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.dayIcon')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.iconPosition')}
                      </ControlLabel>
                      <FormControl
                        componentClass='select'
                        name='bgPos'
                        id='bgPos'
                        placeholder='select'
                        value={bgPos}
                        onChange={e => {
                          setBgPos(e.target.value)
                        }}
                      >
                        <option value='center center'>
                          {t('calDays.formBuilder.xyCenter')}
                        </option>
                        <option value='right bottom'>
                          {t('calDays.formBuilder.xyRight')}
                        </option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={displayByFormStyle}>
                  <Col md={12}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.color')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.backgroundColor')}
                      </ControlLabel>

                      <div style={{ clear: 'both' }} />
                      {_.map(daysGridDataColors, (item, index) => {
                        const style = {
                          background: item,
                          padding: '3 10px',
                          cursor: 'pointer'
                        }
                        return (
                          <div key={index}>
                            <label style={style}>
                              <input
                                type='radio'
                                name='color'
                                defaultValue={item}
                                onChange={e => {
                                  setColor(e.target.value)
                                }}
                                checked={color === item}
                              />{' '}
                              <span style={{ item: item }}>{item}</span>
                            </label>
                          </div>
                        )
                      })}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={8} style={fieldDisplayStyle}>
                    <FormGroup controlId='formControlsImage'>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.articleImage')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.articleImage')}
                      </ControlLabel>
                      <FileSelector
                        type='image'
                        legacy
                        centered
                        activeCalendar={activeCalendar}
                        activeCompany={activeCompany}
                        fileName={selectedForm}
                        pathToUpload='dayImages/'
                        dbPath={'days/' + selectedForm + '/'}
                        dbField='image'
                        url={articleImage}
                        onSelect={(file) => setArticleImage(file.url)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={4} style={displayByFormStyle}>
                    <FormGroup controlId='formControlsImage'>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.dayIcon')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.icon')}
                      </ControlLabel>
                      <FileSelector
                        type='image'
                        legacy
                        centered
                        activeCalendar={activeCalendar}
                        activeCompany={activeCompany}
                        fileName={selectedForm}
                        pathToUpload='dayCustomIcons/'
                        dbPath={'settings/grid/'}
                        dbFieldInArray='days'
                        dbFieldArrayIndex={selectedForm}
                        dbField='customIcon'
                        url={icon}
                        onSelect={(file) => setIcon(file.url)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={displayByFormStyle}>
                  <Col md={6}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.contentType')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.contentType.name')}
                      </ControlLabel>
                      <FormControl
                        componentClass='select'
                        name='contentType'
                        placeholder='select'
                        value={contentType}
                        onChange={(e) => { setContentType(e.target.value) }}
                      >
                        <option value='contentIcon'>
                          {t('calDays.formBuilder.contentType.icon')}
                        </option>
                        <option value='contentRichEditor'>
                          {t('calDays.formBuilder.contentType.iconEditor')}
                        </option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col md={6} />
                </Row>
                <Row style={displayByFormStyle}>
                  {contentType === "contentIcon" && <Col md={4} sm={4}>
                    <FormGroup controlId='formControlsImage'>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.dayIcon')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.iconOnly')}
                      </ControlLabel>
                      <FileSelector
                        type='image'
                        legacy
                        centered
                        activeCalendar={activeCalendar}
                        activeCompany={activeCompany}
                        fileName={selectedForm + 'c'}
                        pathToUpload='dayCustomIcons/'
                        dbPath={'settings/grid/'}
                        dbFieldInArray='days'
                        dbFieldArrayIndex={selectedForm}
                        dbField='customIconContent'
                        url={contentIcon}
                        onSelect={(file) => setContentIcon(file.url)}
                      />
                    </FormGroup>
                  </Col>}
                  {contentType === "contentRichEditor" && <Col xs={6}>
                    <FormGroup validationState={richContentSize >= richTextLimit ? "error" : null}>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('day.dayEditor')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calDays.formBuilder.contentEditor') + ", Image Max Width " + getRichContentMaxWidth(calendarType)}
                      </ControlLabel>
                      <ResizableQuill
                        value={richContent}
                        id='richContent'
                        name='richContent'
                        onChange={(value) => setRichContent(value)}
                      />
                      {richContentSize >= richTextLimit && <HelpBlock>Document too large. Limit: 800kb</HelpBlock>}
                    </FormGroup>
                  </Col>}
                </Row>
              </form>
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default translate('translations')(Settings)
