import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { firebaseAuth } from '../../firebase'
import cloudFunctionRequest, { Method } from '../../helpers/cloudFunction'
import { Checkbox, Modal, Space, Table } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { UserForm } from './UserForm'
import { useForm } from 'antd/lib/form/Form'


const AdminTable = () => {
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(false)
  const [userUpdating, setUserUpdating] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [userToken, setUserToken] = useState()
  const [form] = useForm()

  const getUsers = () => {
    setUsersLoading(true)
    firebaseAuth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => {
        setUserToken(idTokenResult.token)
        cloudFunctionRequest(
          'auth/getAllUsers',
          Method.GET,
          { headers: { "Authorization": `Bearer ${idTokenResult.token}` } }
        ).then(res => {
          setUsers(res.data.data.users.map((item, index) => {
            item.key = index
            const { admin, companies } = item.customClaims || {}
            item.companies = companies && Object.keys(companies)
            item.admin = admin
            return item
          }))
          setUsersLoading(false)
        })
      })
      .catch(error => {
        console.log(error)
        setUsersLoading(false)
      })
  }

  useEffect(() => {
    getUsers()
  }, [])

  const handleUserUpdate = async (user) => {
    setUserUpdating(true)
    const companiesObj = {}
    if (user && user.companies) {
      user.companies.forEach(company => {
        companiesObj[company] = 10
      });
      user.companies = companiesObj
    }
    user.userToken = userToken
    await cloudFunctionRequest(
      'auth/updateUser',
      Method.POST,
      user
    )
    setSelectedUser()
    getUsers()
    setUserUpdating(false)
    form.resetFields()
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setSelectedUser()
    form.resetFields()
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filters: users.map(user => { return { text: user.email, value: user.email } }),
      filterSearch: true,
      onFilter: (value, record) => record.email === value,
      sorter: (a, b) => a.email && a.email.localeCompare(b.email)
    },
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      filters: [...new Set(users.map(user => user.displayName))].map(displayName => { return { text: displayName, value: displayName } }),
      filterSearch: true,
      onFilter: (value, record) => record.displayName === value,
      sorter: (a, b) => a.displayName && a.displayName.localeCompare(b.displayName)
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      filterMultiple: false,
      filters: [
        {
          text: 'Enabled',
          value: false,
        },
        {
          text: 'Disabled',
          value: true,
        }
      ],
      onFilter: (value, record) => record.disabled === value,
      render: (_, record) => (
        <Space size='small'>
          <Checkbox checked={record.disabled} style={{ pointerEvents: 'none' }} />
        </Space>
      )
    },
    {
      title: 'Email Verified',
      dataIndex: 'emailVerified',
      key: 'emailVerified',
      filterMultiple: false,
      filters: [
        {
          text: 'Verified',
          value: true,
        },
        {
          text: 'Not Verified',
          value: false,
        },
      ],
      onFilter: (value, record) => record.emailVerified === value,
      render: (_, record) => (
        <Space size='small'>
          <Checkbox checked={record.emailVerified} style={{ pointerEvents: 'none' }} />
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <EditOutlined onClick={() => setSelectedUser(record)} />
          {/* <DeleteOutlined /> */}
        </Space>
      )
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={users}
        showSorterTooltip={false}
        loading={usersLoading}
      />
      <Modal
        title="Update User"
        open={selectedUser}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={userUpdating}
        destroyOnClose
      >
        <UserForm values={selectedUser} form={form} onSubmit={handleUserUpdate} />
      </Modal>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(AdminTable))

