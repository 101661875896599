import React, { useEffect, useState } from 'react'
import './FileSelector.css'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import FileBrowserModal from './FileBrowserModal'
import { firebaseDb as db } from '../../../firebase'
import { getDownloadURL } from './Functions'


const FileSelector = (props) => {
  const { activeCompany, activeCalendar, dbPath, dbField, dbFieldInArray, dbFieldArrayIndex, pathToUpload, legacy } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [fileUrl, setFileUrl] = useState()
  const [fileName, setFileName] = useState()
  const [previewError, setPreviewError] = useState(false)

  const onClick = () => {
    // open modal
    setModalVisible(true)
  }

  useEffect(() => {
    async function fetchData() {
      if (!props.url && (props.dbField || props.dbFieldInArray) && activeCalendar) {
        const url = await loadImage()
        url && setFileUrl(url)
      } else {
        props.url && setFileUrl(props.url)
      }
    }
    fetchData()
  }, [props.url, dbField, dbFieldInArray, dbPath, props.fileName])

  useEffect(() => {
    if (props.onModalChange) {
      props.onModalChange(modalVisible)
    }
  }, [modalVisible])

  useEffect(() => {
    setPreviewError(false)
    if (fileUrl) {
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('%2F') + 3, fileUrl.lastIndexOf('?'))
      setFileName(fileName)
    }
  }, [fileUrl])

  const onModalOk = (file) => {
    setFileUrl(file.url)
    if (props.onSelect) {
      props.onSelect(file)
    }
    setModalVisible(false)
  }

  const onModalCancel = () => {
    setModalVisible(false)
  }

  const onRemove = () => {
    const url = legacy ? 'removed' : ''
    onModalOk({url: url, metadata: null})
  }

  const loadImage = async () => {
    const querySnapshot = await db.doc(
      'companies/' + activeCompany + '/calendars/' + activeCalendar + '/' + dbPath
    ).get()
    let data = querySnapshot.data()
    if (data) {
      let imageName
      if (dbFieldInArray) {
        if (
          data[dbFieldInArray] &&
          data[dbFieldInArray] &&
          data[dbFieldInArray][dbFieldArrayIndex]
        ) {
          imageName = data[dbFieldInArray][dbFieldArrayIndex][dbField]
        }
      } else {
        imageName = data[dbField]
      }
      if (!imageName || imageName === 'removed' || ( typeof imageName === 'string' && (imageName.includes('https://') || imageName.includes('http://')))) {
        return ''
      }
      const path = 'company/' +
        activeCompany +
        '/calendar/' +
        activeCalendar +
        '/' +
        pathToUpload +
        imageName
      try {
        const url = await getDownloadURL(path)
        return url
      } catch (error) {
        
      }
    }
  }

  return (
    <Row>
      <FileBrowserModal
        visible={modalVisible}
        onCancel={onModalCancel}
        activeCompany={props.activeCompany}
        type={props.type}
        disableDragAndDropProvider={props.disableDragAndDropProvider}
        centered={props.centered}
        onOk={onModalOk} />
      <Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="ant-upload ant-upload-select-picture-card storage-selector-container"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={onClick}
        >
          {
            fileUrl && fileUrl !== 'removed' ?
              (previewError ?
                <p>{fileName}</p> :
                <img src={fileUrl} style={{ maxWidth: '100%', width: 'fit-content' }} alt='backgroundImage' onError={() => setPreviewError(true)} />
              )
              :
              <>
                <FontAwesomeIcon icon={faFolder} size='3x' />
                Upload/Select from storage
              </>
          }
        </div>
        {fileUrl && fileUrl !== 'removed' && <a onClick={onRemove} style={{ marginLeft: '5px' }}>
          <FontAwesomeIcon icon={faTrashCan} />
        </a>}
      </Col>
    </Row>
  )
}

export default FileSelector